const translations = {
    en: {
        completed: "Done!",
        continue: "Keep Going",
        profile: "My Profile",
        assignments: "Tasks",
        payments: "Payments",
        lessons: "Lessons",
        dashboard: "Home",
        progress: "Progress",
        edit_profile: "Update Profile",
        attempts: "Tries",
        comments: "Feedback",
        created: "Created",
        attempt: "Try",
        start: "Start",
        no_notifications: "No notifications to show.",
        no_assignments: "No tasks to show.",
        status: "Status",
        notifications: "Notifications",
        date: "Date",
        no_payments: "No completed payments ⭐",
        current_step_locked: "Complete this step to unlock the next one.",
        step_locked: "Complete the previous steps so that the content is available.",
        reset_progress: "Reset Progress",
        reset_confirm_message: "Are you sure you want to reset your progress? This action cannot be undone."
    },
    ru: {
        completed: "Готово!",
        reset_progress: "Сбросить Прогресс",
        reset_confirm_message: "Вы уверены, что хотите сбросить свой прогресс? Это действие нельзя отменить.",
        continue: "Продолжить",
        start: "Начать",
        profile: "Мой Профиль",
        notifications: "Уведомления",
        no_notifications: "Нет уведомлений для показа.",
        assignments: "Задания",
        lessons: "Уроки",
        payments: "Платежи",
        dashboard: "Главная",
        progress: "Прогресс",
        edit_profile: "Обновить Профиль",
        attempts: "Попытки",
        comments: "Отзывы",
        created: "Создано",
        attempt: "Попробовать",
        no_assignments: "Нет заданий для показа.",
        status: "Статус",
        date: "Дата",
        no_payments: "Нет совершенных платежей ⭐",
        current_step_locked: "Завершите этот шаг, чтобы разблокировать следующий.",
        step_locked: "Завершите предыдущее шаги, чтобы контент был доступен."
    },
    es: {
        completed: "¡Hecho!",
        continue: "Sigue adelante",
        profile: "Mi perfil",
        assignments: "Tareas",
        payments: "Pagos",
        lessons: "Lecciones",
        dashboard: "Inicio",
        progress: "Progreso",
        edit_profile: "Actualizar perfil",
        attempts: "Intentos",
        comments: "Comentarios",
        created: "Creado",
        attempt: "Intentar",
        start: "Comenzar",
        no_notifications: "No hay notificaciones para mostrar.",
        no_assignments: "No hay tareas para mostrar.",
        status: "Estado",
        notifications: "Notificaciones",
        date: "Fecha",
        no_payments: "No hay pagos completados ⭐",
        current_step_locked: "Completa este paso para desbloquear el siguiente.",
        step_locked: "Completa los pasos anteriores para acceder al contenido.",
        reset_progress: "Restablecer progreso",
        reset_confirm_message: "¿Estás seguro de que deseas restablecer tu progreso? Esta acción no se puede deshacer."
    },
    fr: {
        completed: "Terminé !",
        continue: "Continuez",
        profile: "Mon profil",
        assignments: "Tâches",
        payments: "Paiements",
        lessons: "Leçons",
        dashboard: "Accueil",
        progress: "Progression",
        edit_profile: "Mettre à jour le profil",
        attempts: "Essais",
        comments: "Commentaires",
        created: "Créé",
        attempt: "Essayer",
        start: "Commencer",
        no_notifications: "Aucune notification à afficher.",
        no_assignments: "Aucune tâche à afficher.",
        status: "Statut",
        notifications: "Notifications",
        date: "Date",
        no_payments: "Aucun paiement effectué ⭐",
        current_step_locked: "Complétez cette étape pour débloquer la suivante.",
        step_locked: "Complétez les étapes précédentes pour accéder au contenu.",
        reset_progress: "Réinitialiser la progression",
        reset_confirm_message: "Êtes-vous sûr de vouloir réinitialiser votre progression ? Cette action est irréversible."
    },
    de: {
        completed: "Erledigt!",
        continue: "Weitermachen",
        profile: "Mein Profil",
        assignments: "Aufgaben",
        payments: "Zahlungen",
        lessons: "Lektionen",
        dashboard: "Startseite",
        progress: "Fortschritt",
        edit_profile: "Profil aktualisieren",
        attempts: "Versuche",
        comments: "Feedback",
        created: "Erstellt",
        attempt: "Versuchen",
        start: "Starten",
        no_notifications: "Keine Benachrichtigungen zum Anzeigen.",
        no_assignments: "Keine Aufgaben zum Anzeigen.",
        status: "Status",
        notifications: "Benachrichtigungen",
        date: "Datum",
        no_payments: "Keine abgeschlossenen Zahlungen ⭐",
        current_step_locked: "Schließen Sie diesen Schritt ab, um den nächsten freizuschalten.",
        step_locked: "Schließen Sie die vorherigen Schritte ab, um auf den Inhalt zuzugreifen.",
        reset_progress: "Fortschritt zurücksetzen",
        reset_confirm_message: "Sind Sie sicher, dass Sie Ihren Fortschritt zurücksetzen möchten? Diese Aktion kann nicht rückgängig gemacht werden."
    },
    zh: {
        completed: "完成！",
        continue: "继续前进",
        profile: "我的资料",
        assignments: "任务",
        payments: "付款",
        lessons: "课程",
        dashboard: "主页",
        progress: "进度",
        edit_profile: "更新资料",
        attempts: "尝试次数",
        comments: "反馈",
        created: "已创建",
        attempt: "尝试",
        start: "开始",
        no_notifications: "暂无通知。",
        no_assignments: "暂无任务。",
        status: "状态",
        notifications: "通知",
        date: "日期",
        no_payments: "暂无已完成付款 ⭐",
        current_step_locked: "完成此步骤以解锁下一个。",
        step_locked: "完成前面的步骤以解锁内容。",
        reset_progress: "重置进度",
        reset_confirm_message: "您确定要重置进度吗？此操作无法撤销。",
    },
};

export function translate(languageCode, key) {
    return translations[languageCode]?.[key] || translations.en[key];
}